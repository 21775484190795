import React from "react";



const German = {
    button_title:"Starten Sie ein neues Spiel",
    turns_count:"Umdrehungen zählen",
    success_heading:"Hurra! Alle Kacheln werden umgedreht!",
    close_message:"Schließen",
    rank_name:"Errungenschaft freigeschaltet",
    rank_one:"🧙‍♂️ Magier",
    rank_two:"👨‍🎓 Akademiker",
    rank_three:"👻 Lehrling",
    loading:"Bitte warten, das Spiel wird geladen..."
}

const Russian = {
    button_title:"Начать новую игру",
    turns_count:"Количество ходов",
    success_heading:"Ура! Все плиты открыты!",
    close_message:"Начать заново",
    rank_name:"Открыто достижение",
    rank_one:"🧙‍♂️ Волшебник",
    rank_two:"👨‍🎓 Академик",
    rank_three:"👻 Ученик",
    loading:"Подождите, пожалуйста, игра загружается..."
}

const Thai = {
    button_title:"เริ่มเกมใหม่",
    turns_count:"หมุนนับ",
    success_heading:"เย่! กระเบื้องทั้งหมดพลิก!",
    close_message:"ใกล้",
    rank_name:"ปลดล็อคความสำเร็จ",
    rank_one:"🧙‍♂️ พ่อมด",
    rank_two:"👨‍🎓 นักวิชาการ",
    rank_three:"👻 เด็กฝึกงาน",
    loading:"กรุณารอสักครู่ กำลังโหลดเกม..."
}

const Portuguese = {
    button_title:"Iniciar um novo jogo",
    turns_count:"Contagem de voltas",
    success_heading:"Viva! Todas as peças estão viradas!",
    close_message:"Fechar",
    rank_name:"Conquista desbloqueada",
    rank_one:"🧙‍♂️ Mago",
    rank_two:"👨‍🎓 Acadêmico",
    rank_three:"👻 Aprendiz",
    loading:"Aguarde, o jogo está sendo carregado..."
}

const Italian = {
    button_title:"Inizia una nuova partita",
    turns_count:"I giri contano",
    success_heading:"Evviva! Tutte le tessere sono capovolte!",
    close_message:"Chiudere",
    rank_name:"Obiettivo sbloccato",
    rank_one:"🧙‍♂️ Procedura Guidata",
    rank_two:"👨‍🎓 Accademico",
    rank_three:"👻 Apprendista",
    loading:"Attendi, il gioco è in fase di caricamento..."
}

const English = {
    button_title:"Start new game",
    turns_count:"Turns count",
    success_heading:"Hurray! All the tiles are flipped!",
    close_message:"Play again",
    rank_name:"Achievement unlocked",
    rank_one:"🧙‍♂️ Wizard",
    rank_two:"👨‍🎓 Academician",
    rank_three:"👻 Apprentice",
    loading:"Please wait, the game is being loaded..."
}

const Spanish = {
    button_title: "Iniciar un nuevo juego",
    turns_count:"Conteo de vueltas",
    success_heading:"¡Viva! ¡Todas las fichas están volteadas!",
    close_message:"Jugar de nuevo",
    rank_name:"Logro desbloqueado",
    rank_one:"🧙‍♂️ Mago",
    rank_two:"👨‍🎓 Académico",
    rank_three:"👻 Aprendiz",
    loading:"Por favor espere, el juego se está cargando..."
}

const Polish = {
    button_title: "Rozpocznij nową grę",
    turns_count: "Liczba obrotów",
    success_heading:"Hurra! Wszystkie płytki są odwrócone!",
    close_message:"Zewrzeć",
    rank_name:"Osiągnięcie odblokowane",
    rank_one:"🧙‍♂️ Czarodziej",
    rank_two:"👨‍🎓 Akademicki",
    rank_three:"👻 Praktykant",
    loading:"Proszę czekać, trwa ładowanie gry..."
}

const French = {
    button_title: "Démarrer une nouvelle partie",
    turns_count: "Les tours comptent",
    success_heading:"Hourra! Toutes les tuiles sont ouvertes!",
    close_message:"Fermer",
    rank_name:"Succès dévérouillé",
    rank_one:"🧙‍♂️ Sorcier",
    rank_two:"👨‍🎓 Académicien",
    rank_three:"👻 Apprenti",
    loading:"Veuillez patienter, le jeu est en cours de chargement..."
}

const international = {
    Russian:Russian,
    German:German,
    Thai:Thai,
    Portuguese:Portuguese,
    Italian:Italian,
    English:English,
    Spanish:Spanish,
    Polish:Polish,
    French:French
}

export default international