import React, {useEffect, useState} from 'react';
import international from './Locals/Locals';
import './App.css';

import MagicalTiles from './MagicalTiles/MagicalTiles';




function App() {

  const isMobile = () => {
    if(window.navigator.userAgent.indexOf('Mobile') !== -1){
        return true;
    } else {
        return false;
    }   
}

  const browserLang = () => {
    let language = (window.navigator.language ||
                      window.navigator.systemLanguage ||
                      window.navigator.userLanguage);

    language = language.substr(0, 2).toLowerCase();
    return language;
}


    let chosenLanguage;
    
    const languageSetter = (param) => { 
      switch(param) {
        case "ru":
          return chosenLanguage = international.Russian;
        case "kk":
          return chosenLanguage = international.Russian
        case "de":
          return chosenLanguage = international.German;
        case "en":
          return chosenLanguage = international.English;
        case "pt":
          return chosenLanguage = international.Portuguese;
        case "es":
          return chosenLanguage = international.Spanish;
        case "fr":
          return chosenLanguage = international.French;
        case "it":
          return chosenLanguage = international.Italian;
        case "th":
          return chosenLanguage = international.Thai;
        case "pl":
          return chosenLanguage = international.Polish;
        default:
          return chosenLanguage = international.English;
      }
    }
          


  languageSetter(browserLang())
  

  const attribution = (param) => {
    if(param === true) {
      return <span></span>
    } else {
      return <a className='attribution' target='_blank' href="https://www.freepik.es/autor/upklyak">Vector images created by upklyak - www.freepik.com</a>
    }
  }


  return (
    <div className='mainApp'>
      <MagicalTiles language={chosenLanguage}/>
      {attribution(isMobile())}
    </div>
  );
}

export default App;
