import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import international from './Locals/Locals';
import "./Main.css"
import tileLogo from './graphics/logo.svg'


function Main() {

  const [serverResponse, setServerResponse] = useState(null)


  const getUTM = (utm) => {
    let params = new URLSearchParams(document.location.search);
    let utmQuery = params.get(`${utm}`);

    return utmQuery;
}

const setStorageValue = (key, value) => {
    if(!window.localStorage.getItem(`${key}`)) {
        window.localStorage.setItem(`${key}`, JSON.stringify(value));
        return value;
    } 
    
    else if(window.localStorage.getItem(`${key}`)) {
        return JSON.parse(window.localStorage.getItem(`${key}`));
    }
}

  const isWebview = () => {
      let userAgent = window.navigator.userAgent;
      let userAgentArray = userAgent.split(' ');
      let result;
  
      userAgentArray.forEach(pair => {
                 if(pair.includes('BytedanceWebview') || pair.includes('ByteLocale')) {
                      result = true;
                  } else {
                      result = false;
                  }
              });
  
      return result;
  }
  
  const androidVersion = () => {
      const userAgent = navigator.userAgent;
      userAgent.toString().toLowerCase();
      const match = userAgent.match((/android\s([0-9\.]*)/i));
      return match ? match[1] : false;
  }
  
  const browserLang = () => {
      let language = (window.navigator.language ||
                        window.navigator.systemLanguage ||
                        window.navigator.userLanguage);
  
      language = language.substr(0, 2).toLowerCase();
      return language;
  }
  
  const isMobile = () => {
      if(window.navigator.userAgent.indexOf('Mobile') !== -1){
          return true;
      } else {
          return false;
      }   
  }
  
  const isMultiTouch = () => {
      if(navigator.maxTouchPoints === 1 || navigator.maxTouchPoints < 1) {
          return false;
      } else if(navigator.maxTouchPoints > 1){
          return true;
      }
  }
  
  const hasChrome = () => {
      let isChromium = window.chrome;
      let winNav = window.navigator;
      let vendorName = winNav.vendor;
      let isOpera = typeof window.opr !== "undefined";
      let isIEedge = winNav.userAgent.indexOf("Edg") > -1;
      let isIOSChrome = winNav.userAgent.match("CriOS");
  
      if (isIOSChrome) {
          // iOS Chrome has to be ignored
         return false
      } else if(
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
      ) {
         // is Google Chrome
         return true
      } else { 
          return false
         // not Google Chrome 
      }
  }
  
  const chromeVersion = () => {     
      var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  
      return raw ? parseInt(raw[2], 10) : false;
  }
  
  const pwaInstalled = () => {
      if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {  
          return true;  
      } else {
          return false;
      }
  }
  
  const connection = () => {
      let array = [];
      array.push("Сеть: " + navigator.connection.effectiveType);
      array.push("Тип подключения: " + navigator.connection.type);
      array.push("RTT: " + navigator.connection.rtt);
      array.push("Downlink: " + navigator.connection.downlink);
      return array;
  }
  
  const handleOrigin = () => {
      let empty = "" || " "
      if(document.referrer === empty || !document.referrer) {
          return 'none';
      } else {
          return document.referrer;
      }
  }

  const batteryState = () => {
    let batteryIsCharging;

    navigator.getBattery().then(function(battery) {
      batteryIsCharging = battery.charging;
    
      battery.addEventListener('chargingchange', function() {
        batteryIsCharging = battery.charging;
      });
    });

return batteryIsCharging;
}



const trySplit = (str, num) => {
  if(str) {
    let a = str.split('_')
    return a[num]
    }
  if(!str) {
  return 'default'
    }
  }

  

  const Visitor = () => new Object();
      Visitor.multiTouch = isMultiTouch();
      Visitor.key = setStorageValue('key', new Date().getTime());
      Visitor.origin = handleOrigin();
      Visitor.hasChrome = hasChrome();
      Visitor.chromeVersion = chromeVersion();
      Visitor.pwaInstalled = pwaInstalled();
      Visitor.campaign = trySplit(getUTM('campaign'), 0);
      Visitor.adset = getUTM('afadset');
      Visitor.browserLang = browserLang();
      Visitor.userLanguage = browserLang();
      Visitor.androidVersion = androidVersion();
      Visitor.isMobile = isMobile();
      Visitor.isWebview = isWebview();
      Visitor.battery = batteryState();
      Visitor.clickid = trySplit(getUTM('afadset'), 1);
      Visitor.pixel = trySplit(getUTM('afadset'), 2);
      Visitor.uage = window.navigator.userAgent;
      Visitor.advertising_id = getUTM('advertising_id');

      
    const populateLocalStorage = (object) => {
        localStorage.setItem('ip', object.ip);
        localStorage.setItem('city', object.city);
        localStorage.setItem('country', object.country);
    }
    const fetchData = async(url) => {
        const location = await fetch(url);
        const locationJson = await location.json();
        const locationInfo = {
            ip: locationJson.ip,
            city: locationJson.city,
            country: locationJson.country_name,
        }
        populateLocalStorage(locationInfo);
        return locationInfo;
    }


    const generateObject = async(locationAPI) => {
        if(localStorage.getItem('ip') !== null && localStorage.getItem('city') !== null && localStorage.getItem('country') !== null) {
            const locationObject = {
                ip:localStorage.getItem('ip'),
                city:localStorage.getItem('city'),
                country:localStorage.getItem('country')
            }
            return locationObject;
        } else {
            return await fetchData(locationAPI)
        }
    }

    const sendDataToServer = async(data) => {
      const finalObject = {
        isMultiTouch:Visitor.multiTouch,
        key:Visitor.key,
        origin:Visitor.origin,
        hasChrome:true,
        chromeVersion:Visitor.chromeVersion,
        pwaInstalled:Visitor.pwaInstalled,
        campaign:Visitor.campaign,
        adset:Visitor.adset,
        utmterm:Visitor.utmterm,
        browserLang:Visitor.browserLang,
        netType:navigator.connection.effectiveType,
        connectionType:navigator.connection.type || "Не определено",
        downlink:navigator.connection.downlink,
        userLanguage:Visitor.userLanguage,
        androidVersion:Visitor.androidVersion,
        isMobile:Visitor.isMobile,
        isWebview:false,
        clickid:Visitor.clickid,
        userAgent:window.navigator.userAgent,
        page:window.location.href,
        personal_code:Visitor.personal_code,
        advertising_id:Visitor.advertising_id,
        pixel:Visitor.pixel,
        ...await data
      }

      const reqOptions = {
        method: "POST",
              headers: {'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'}, 
              body: JSON.stringify(finalObject)
      }

      const sendToMagicalTilesServer = await fetch('/api/magical-naming-event', reqOptions)
      const serverResponse = await sendToMagicalTilesServer.json()

      setServerResponse(serverResponse)
    }

      useEffect(() => {
        sendDataToServer(generateObject('https://ipapi.co/json/'))
    }, [])

    const startMenu =     <div className='major'>
    <div className='major__intro'>
        <img className="logo" height="60" width="auto"  src={tileLogo}/>
        <h1 className='major__title'>Magical tiles</h1>
        <Link to="/app"><button className='major__button'>Start playing!</button></Link>
    </div>
</div>

    let decision;


    let chosenLanguage;
    
    const languageSetter = (param) => { 
      switch(param) {
        case "ru":
          return chosenLanguage = international.Russian;
        case "kk":
          return chosenLanguage = international.Russian
        case "de":
          return chosenLanguage = international.German;
        case "en":
          return chosenLanguage = international.English;
        case "pt":
          return chosenLanguage = international.Portuguese;
        case "es":
          return chosenLanguage = international.Spanish;
        case "fr":
          return chosenLanguage = international.French;
        case "it":
          return chosenLanguage = international.Italian;
        case "th":
          return chosenLanguage = international.Thai;
        case "pl":
          return chosenLanguage = international.Polish;
        default:
          return chosenLanguage = international.English;
      }
    }

  languageSetter(browserLang())

    if(serverResponse === null) {
      // <p className='game__loading'>{chosenLanguage.loading}</p>
      return decision = <div className='loading-screen'></div>
    } 
    else if(serverResponse.code === 222) {
       return decision = startMenu
    } 
    else if(serverResponse.code === 111) {
      return decision = startMenu
    } 
    else if(serverResponse.code === 444) {
      return decision = startMenu
    } 
    else if(serverResponse.code === 777) {
      decision = document.location.replace(serverResponse.destination)
    } 
    else if(serverResponse.code === 333) {
      return decision = startMenu
    } 
    else {
      return decision = startMenu
    }


    const attribution = (param) => {
      if(param === true) {
        return <span></span>
      } else {
        return <a className='attribution' target='_blank' href="https://www.freepik.es/autor/upklyak">Vector images created by upklyak - www.freepik.com</a>
      }
    }

  return (
    <>
      {decision}
      {attribution}
    </>
  );
}

export default Main;
