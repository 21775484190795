import React, { useEffect, useState } from "react";
import "./SingleCard.css"

import chest from "../../graphics/tile-chest.png"


const SingleCard = ({card, handleChoice, flipped, disabled}) => {

    const handleBackClick = () => {
        if(!disabled) {
            handleChoice(card)
        }
    } 

    return(
        <div className="card" key={card.id}>
            <div className={flipped ? "flipped" : " "}>
                <img className="card__img card__front" src={card.src}/>
                <img 
                    onClick={handleBackClick} 
                    className="card__img card__back" 
                    src={chest}/>
            </div>
        </div>
    )
}

export default SingleCard