import React, { useEffect, useState } from "react";
import {Navigate} from 'react-router-dom'
import SingleCard from "../Components/SingleCard/SingleCard";
import "./MagicalTiles.css"
import tileCrown from "../graphics/tile-crown.png"
import tileCoinEye from "../graphics/tile-coin-eye.png"
import tileCoinStar from "../graphics/tile-coin-star.png"
import tileCup from "../graphics/tile-cup.png"
import tileGear from "../graphics/tile-gear.png"
import tileKey from "../graphics/tile-key.png"


const levelOne = [
    {
        "src":tileCrown, 
        matched:false
    },
    {
        "src":tileCoinEye, 
        matched:false
    },
    {
        "src":tileCoinStar, 
        matched:false
    },
    {
        "src":tileCup, 
        matched:false
    },
    {
        "src":tileGear, 
        matched:false
    },
    {
        "src":tileKey, 
        matched:false
    }
]


const MagicalTiles = (props) => {
    const translated = props.language;

    const [cards, setCards] = useState([])
    const [turns, setTurns] = useState(0)
    const [choiceOne, setChoiceOne] = useState(null)
    const [choiceTwo, setChoiceTwo] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [flippedCards, setFlippedCards] = useState(0)

   
    const shuffle = () => {
        const shuffledCards  = [...levelOne, ...levelOne]
            .sort(() => Math.random() - 0.5)
            .map((card) => ({...card, id: Math.random() }))
        
        setChoiceOne(null)
        setChoiceTwo(null)
        setCards(shuffledCards)
        setTurns(0)
    }

    // handle a choice
    const handleChoice = (card) => {
        choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
    }


    // compare cards
    useEffect(()=> {
        if(choiceOne && choiceTwo) {
            setDisabled(true)
            if(choiceOne.src === choiceTwo.src) {
                setCards(prevCards => {
                    return prevCards.map(card => {
                        if(card.src === choiceOne.src) {
                            let flipped = document.getElementsByClassName('flipped');
                            setFlippedCards(flipped.length)
                            return {...card, matched:true}
                        } else {
                            return card
                        }
                    })
                })
                resetTurn()
            } else {
                setTimeout(() => {
                    resetTurn()
                }, 900);
                
            }
        }
    }, [choiceOne, choiceTwo])



    // reset turns
    const resetTurn = () => {
        setChoiceOne(null)
        setChoiceTwo(null)
        setTurns(prevTurns => prevTurns +1)
        setDisabled(false)
    }

    useEffect(()=> {
        shuffle()
    },[])

    const rankSetter = (rounds) => {
        if(parseInt(rounds)<12 || parseInt(rounds)===12) {
            return translated.rank_one
        } else if(parseInt(rounds)>12 && parseInt(rounds)<26) {
            return translated.rank_two
        } else {
            return translated.rank_three
        }
    }
    
    var setter = 0;
    setInterval(()=> {
        if(setter===0) {
            let tiles = document.getElementsByClassName('flipped');
            if(tiles.length === 12) {
                setTimeout(()=>{
                    setter = 1
                    document.getElementById('successBox').style.display="flex"
                },100)
            }
        }
    },1500)

    const updateGame = () => {
        shuffle()
        document.getElementById('successBox').style.display="none";
        setter = 0
    }

    const resetAppData = () => {
        setFlippedCards(0)
        updateGame()
    }


    let successAlert = <div id="successBox" className="success">
        <h4 className="success__heading">{translated.success_heading}</h4>
        <p className="success__paragraph success__turns-count">{translated.turns_count}: {turns}</p>
        <p className="success__paragraph success__rank">{translated.rank_name}: {rankSetter(turns)}</p>
        <button className="success__button" onClick={resetAppData}>
            {translated.close_message}
        </button>
    </div>
        

    const newGameDisabledButton = <button id="startButton" className="main__start-button disabled">{translated.button_title}</button>

    const newGameEnabledButton = <button id="startButton" className="main__start-button enabled" onClick={shuffle}> {translated.button_title}</button>
      
  return (
    <div id="bd">
        
        <div className="main">
            {flippedCards > 12? successAlert : null}
        {successAlert}

        {turns === 0 ?  newGameDisabledButton : newGameEnabledButton}

            <div className="cards-cascade">
                {cards.map((card, id) => (
                    <SingleCard 
                    key={card.id} 
                    card={card}
                    handleChoice={handleChoice}
                    flipped={card === choiceOne || card === choiceTwo || card.matched}
                    disabled={disabled}
                    />
                ))}
            </div>
        </div>
    </div>
  );
};

export default MagicalTiles;
